import React, { Component } from "react";
import { MDBCol, MDBIcon, MDBContainer, MDBRow, MDBFooter } from "mdbreact";


class Footer extends Component {
    // constructor(props) {
    //     super(props)
    // }

    render() {
        return (
            <MDBFooter color="default-color-dark" className="font-small pt-4 mt-4" >
                <MDBContainer fluid >
                    <MDBRow>

                        <MDBCol col="6" className="title text-center">
                            {/* <h5 className="title">Кабинет партнера TEEAL</h5> */}
                            {/* <p> <a target="_blank" rel="noopener noreferrer" href="https://teeal.pl/">Остаемся на связи )</a>
                            </p> */}
                            <h5 className="title text-center" >Остаемся на связи</h5>
                            <a target="_blank" rel="noopener noreferrer" href="https://teeal.pl/"><MDBIcon size="2x" style={{ margin: "7px" }} icon="globe" /></a>

                            <a href="https://invite.viber.com/?g2=AQAdJaDmvBl%2FHEhfB1zpDFXf1hOWn4JGIlxBFJ3c3Ju8bYFumFMuxTxjwUxS51Oh"><MDBIcon size="2x" style={{ margin: "7px" }} fab icon="viber" /></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/teeal.p"><MDBIcon size="2x" style={{ margin: "7px" }} fab icon="facebook-square" /></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://t.me/TEEALpl"><MDBIcon size="2x" style={{ margin: "7px" }} fab icon="telegram" /></a>
                            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCBa57nSErro8MGdS9Xnd6JA"><MDBIcon size="2x" style={{ margin: "7px" }} fab icon="youtube" /></a>
                            <a target="_blank" rel="noopener noreferrer" href="mailto:info@teeal.pl"><MDBIcon size="2x" style={{ margin: "7px" }} icon="envelope" /></a>
                            <div className="text-center">
                                {/* <h4>    <a className="title text-center" href="tel:0800331895" >
                                    <MDBIcon style={{ margin: "15px" }} icon="mobile-alt" />0800 33 18 95</a></h4> */}
                            </div>
                        </MDBCol>
                        {/* <MDBCol col="6">
                            <h6 className="title text-center">Специалисты TEEAL</h6>
                            <div className="text-center">
                                <a href="tel:0675797838">
                                    <MDBIcon style={{ margin: "5px" }} icon="mobile-alt" />Александр</a>
                            </div>
                            <div className="text-center">
                                <a href="tel:0675797838">
                                    <MDBIcon style={{ margin: "5px" }} icon="mobile-alt" />Александр</a>
                            </div>
                            <div className="text-center">
                                <a href="tel:0675797838">
                                    <MDBIcon style={{ margin: "5px" }} icon="mobile-alt" />Александр</a>
                            </div>
                            <div className="text-center">
                                <a href="tel:0675797838">
                                    <MDBIcon style={{ margin: "5px" }} icon="mobile-alt" />Александр</a>
                            </div>
                            <div className="text-center">
                                <a href="tel:0675797838">
                                    <MDBIcon style={{ margin: "5px" }} icon="mobile-alt" />Александр</a>
                            </div>
                            <div className="text-center">
                                <a href="tel:0675797838">
                                    <MDBIcon style={{ margin: "5px" }} icon="mobile-alt" />Александр</a>
                            </div>
                            <div className="text-center">
                                <a href="tel:0675797838">
                                    <MDBIcon style={{ margin: "5px" }} icon="mobile-alt" />Александр</a>
                            </div>
                            <div className="text-center">
                                <a href="tel:0675797838">
                                    <MDBIcon style={{ margin: "5px" }} icon="mobile-alt" />Александр</a>
                            </div>


                        </MDBCol> */}
                    </MDBRow>
                </MDBContainer >


                <div className="footer-copyright text-center py-3">
                    <MDBContainer fluid>
                        &copy;  2015 — {new Date().getFullYear()}. Спасибо, что вы с <a href="https://teeal.pl/"> TEEAL </a>
                    </MDBContainer>
                </div>

            </MDBFooter >
        );
    }
}

export default Footer;