import React, { Component } from "react";
import { MDBModal, MDBModalFooter, MDBModalBody, MDBInput, MDBModalHeader, MDBBtn, } from "mdbreact";



class Enter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            modalEnter: true,
            modalReg: false,
            nameUser: '',
            phoneUser: '',
            errorLogin: '',
            erorValidate: ''
        }
    };

    writeToUser = (UserID, nameUser, phoneUser) => {
        fetch(`${this.props.writeToUser}?auth=0932foAgfuir97f33&UserID=${UserID}&nameUser=${nameUser}&phoneUser=${phoneUser}`);

    }

    validateForm = () => {
        let phone = this.state.phoneUser;

        if (phone !== '') {

            let decPhon = this.decorPhone(phone);
            if (decPhon) {
                return true;
            } else {
                this.setState({ erorValidate: 'телефон должен состоять минимум из 10 цифр' })
                return false;
            }

        } else if (phone === '') {
            this.setState({ erorValidate: 'Введите номер телефона' });
            return false;

        }

    }

    decorPhone = (phone) => {

        phone = phone.replace(/\D+/g, '');
        // console.log(phone);
        var lenPhone = phone.length;
        var tt = phone.split('');

        if (lenPhone < 10) {
            return (false)
        } else if (lenPhone === 10) {
            tt.splice(0, 0, "+38")
        } else if (lenPhone === 11) {
            tt.splice(0, 0, "+3");
        } else if (lenPhone === 12) {
            tt.splice(0, 0, "+");
        }
        return (tt.join(''))


    }




    chekUser() {
        const isUser = localStorage.getItem('UserID');
        if (isUser) {
        }
        else {
            // console.log(isUser);
            this.setState({ modalEnter: false })
        }
    }

    // componentDidMount() {
    //     console.log('Enter comp')
    //     console.log(this.props);
    // }



    toggle = nr => () => {
        let modalNumber = nr
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
        // console.log(nr)
    }


    handleInputChange = inputName => value => {
        const nextValue = value;
        this.setState({
            [inputName]: nextValue
        });
    };

    submitEnter = (e) => {
        e.preventDefault();
        this.enterUser();
    }
    submitRegister = (e) => {
        e.preventDefault();
        this.regUser();
    }

    regUser = () => {
        this.setState({
            modalReg: false,
            errorLogin: '',
            erorValidate: ''

        });

        if (this.state.nameUser !== '') {
            // fetch('https://club.teeal.pl/dat/createCont.php', {
            fetch(this.props.createCont, {
                method: 'POST',
                // mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: JSON.stringify({ 'phone': this.state.phoneUser, 'name': this.state.nameUser })
            })
                .then((response) => {
                    return response.json();
                })
                .then((answ) => {
                    // console.log(answ)
                    if (answ.status === 'true') {

                        this.setState({ userID: answ.clintID })
                        localStorage.setItem('UserID', answ.clintID);
                        localStorage.setItem('nameUser', this.state.nameUser);
                        localStorage.setItem('phoneUser', this.state.phoneUser)

                        this.writeToUser(answ.clintID, this.state.nameUser, this.state.phoneUser)//пишу в базу клиинта

                        this.setState({
                            modalReg: false
                        });
                        window.location.href = '/';
                    }
                    else {

                        localStorage.setItem('phoneUser', this.state.phoneUser);
                        this.setState({
                            modalEnter: false,
                            modalReg: true
                        })
                    }

                }, (error) => {
                    this.setState({
                        modalEnter: true,
                        errorLogin: 'Извините. 😟 Ошибка соединеия с сервером, ' + error + ' .Проверьте, что у вас включен интернет и попробуйте чере пару минут снова'
                    })
                }

                )
        } else {
            this.setState({ modalReg: true, erorValidate: 'Пожалуйста введите ваше имя' })
        }

    }





    enterUser = () => {
        let isValidate = this.validateForm();
        if (isValidate) {

            this.setState({
                modalEnter: false,
                errorLogin: ''
            });

            //fetch('http://bonus-prod/v2/club-teeal/dat/bitrEnter.php', {
            fetch(this.props.bitrEnter, {
                method: 'POST',

                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: JSON.stringify({ 'phone': this.state.phoneUser })
            })
                .then((response) => {
                    // console.log(response);
                    return response.json();
                })
                .then((answText) => {

                    // console.log(answText)
                    if (answText.status === 'true') {//успех вошел
                        this.props.updateData(answText.name)
                        this.setState({ userID: answText.clintID })
                        localStorage.setItem('UserID', answText.clintID);
                        localStorage.setItem('nameUser', answText.name);
                        localStorage.setItem('phoneUser', this.state.phoneUser);

                        this.writeToUser(answText.clintID, answText.name, this.state.phoneUser)//пишу в базу клиента

                        //localStorage.setItem('cardUser', answText.card);
                        this.setState({
                            modalEnter: false,
                            errorLogin: ''
                        });
                        window.location.href = '/'
                    }
                    else if (answText.status === 'emptyContact') {
                        localStorage.setItem('phoneUser', this.state.phoneUser);
                        this.setState({
                            modalEnter: false,
                            modalReg: true,
                            erorValidate: ''
                        })
                    }
                    else if (answText.status === 'noConect') {
                        this.setState({
                            modalEnter: true,
                            erorValidate: '',
                            errorLogin: 'Извините. 😟 Ошибка соединеия с сервером. Проверьте, что у вас включен интернет и попробуйте чере пару минут снова'
                        })
                    }

                }, (error) => {
                    this.setState({
                        erorValidate: '',
                        modalEnter: true,
                        errorLogin: 'Извините. 😟 Ошибка соединеия с сервером, ' + error +
                            ' .Проверьте, что у вас включен интернет и попробуйте чере пару минут снова'
                    })
                }
                )
        }
    }

    falseToogle = () => {
        return false
    }

    render() {

        return (
            <React.Fragment >
                <MDBModal isOpen={this.state.modalEnter} toggle={this.toggle}>
                    <MDBModalHeader
                        className="text-center"
                        titleClass="w-100 font-weight-bold"
                    >

                        Кабинет участника реферальной программы TEEAL. <br /> Для входа или регистрации введите ваш номер телефона
    </MDBModalHeader>
                    <MDBModalBody>
                        <form name='enter' className="mx-3 grey-text" onSubmit={this.submitEnter}>
                            <MDBInput
                                name="phone"
                                label="ваш номер 380 123 4567"
                                icon="phone"
                                group
                                type="number"
                                value={this.state.phoneUser}
                                getValue={this.handleInputChange("phoneUser")}
                            />
                            <p style={{ color: 'red' }}>{this.state.erorValidate}</p>
                        </form>
                    </MDBModalBody>
                    <MDBModalFooter className="justify-content-center">
                        <p className=" text-center" style={{ color: 'red' }}>{this.state.errorLogin}</p>
                        <MDBBtn
                            color="info"
                            onClick={() => {
                                this.toggle('modalEnter');
                                this.enterUser();
                            }}
                        > Вход</MDBBtn>

                    </MDBModalFooter>
                </MDBModal>


                {/* Окно регистрации */}

                <MDBModal isOpen={this.state.modalReg} toggle={this.toggle}>
                    <MDBModalHeader
                        className="text-center"
                        titleClass="w-100 font-weight-bold"
                    >К сожалению не нашли клиента с номером {this.state.phoneUser}.
                    Введите ваше имя для регистрации
                    </MDBModalHeader>
                    <MDBModalBody>
                        <form name='enter' className="mx-3 grey-text" onSubmit={this.submitRegister}>
                            <MDBInput
                                name="location"
                                label=""
                                icon="user"
                                group
                                type="name"
                                value={this.state.nameUser}
                                getValue={this.handleInputChange("nameUser")}
                            />
                            <p className=" text-center" style={{ color: 'red' }}>{this.state.erorValidate}</p>
                        </form>
                    </MDBModalBody>
                    <MDBModalFooter className="justify-content-center">
                        <MDBBtn
                            color="info"
                            onClick={() => {
                                this.toggle('modalReg');
                                this.regUser();
                            }}
                        > Регистрация</MDBBtn>

                        <MDBBtn outline
                            href="/"
                            color="warning"
                            size="sm"
                        > Изменить номер</MDBBtn>
                        <p className=" text-center" style={{ color: 'red' }}>{this.state.errorLogin}</p>
                    </MDBModalFooter>
                </MDBModal>







            </React.Fragment >)

    }
}

export default Enter