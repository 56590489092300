import React, { Component } from "react";
//import ReactDOM from "react-dom";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
//import Enter from './Enter'
import { MDBIcon, MDBInput, MDBBtn, MDBRow, MDBCol, MDBListGroup, MDBListGroupItem, MDBContainer, MDBBadge } from "mdbreact";
import "./Cabinet.css";
//import NewLid from './NewLid'
import NewRequest from './NewRequest';
import Faq from './Faq';
import GetAllrefLid from './getAllrefLid'


class Cabinet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            textRecom: `
            Рекомендую TEEAL, для оформления визы в Польшу. Получи бонус по моей ссылке. https://teeal.pl/refer?v=c${this.props.UserID}`,
            countLid: <MDBIcon icon="spinner" pulse size="1x" fixed />,
            countDeal: <MDBIcon icon="spinner" pulse size="1x" fixed />,
            countisPaid: <MDBIcon icon="spinner" pulse size="1x" fixed />,
            errorUpdate: ''

        }
    }

    componentDidMount () {
        this.getStat();
        // console.log('state Cabinet');
        // console.log(this.state);
        // console.log(this.props);
    }


    getStat = () => {
        this.setState({
            errorUpdate: ''
        })

        if (this.props.UserID) {

            //  fetch('http://bonus-prod/v2/club-teeal/dat/getStat.php', {
            fetch(this.props.getStat, {
                method: 'POST',
                // mode: 'no-cors',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: JSON.stringify({ 'UserID': this.props.UserID })
            })
                .then((response) => {
                    // console.log(response);
                    return response.json();
                })
                .then((answText) => {
                    // console.log(answText)
                    if (answText.status === 'ok') {
                        // console.log('Stat');
                        // console.log(answText);

                        this.setState({
                            countLid: answText.lid,
                            countDeal: answText.deal,
                            countisPaid: answText.isPaid
                        })
                        // window.location.href = '/'
                    }
                    else {
                        // console.log('erorStat');
                    }

                }, (error) => {
                    this.setState({
                        errorUpdate: 'Извините. 😟 Ошибка соединеия с сервером, ' + error + ' .Проверьте, что у вас включен интернет и перезагрузите страницу'
                    })
                }

                )
        }
    }




    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
    }

    handleInputChange = inputName => value => {
        const nextValue = value;
        this.setState({
            [inputName]: nextValue
        });
    };

    viberText = () => {
        let text = 'viber://forward?text=' + this.state.textRecom
        return text
    }

    render () {
        return (


            <React.Fragment>
                <MDBContainer className='d-flex justify-content-center cabinetAll'>

                    <MDBRow>
                        <MDBCol md="7" className="mb-c text-center">
                            <h2 className="text-uppercase my-3">{this.props.nameUser}, По вашей рекомендации</h2>
                            <MDBListGroup className="grupRez">

                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <h5> Всего заявок</h5>
                                    <h4><MDBBadge >{this.state.countLid}</MDBBadge></h4>
                                </MDBListGroupItem>

                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <h5>Подтверждено</h5>
                                    <h4><MDBBadge>{this.state.countDeal}</MDBBadge></h4>
                                </MDBListGroupItem>

                                <MDBListGroupItem className="d-flex justify-content-between align-items-center">
                                    <h5>Сделано выплат</h5>
                                    <h4> <MDBBadge >{this.state.countisPaid}</MDBBadge></h4>
                                </MDBListGroupItem>
                                <GetAllrefLid UserID={this.props.UserID}
                                    getAllrefLid={this.props.getAllrefLid} />

                            </MDBListGroup>
                            <p className=" text-center" style={{ color: 'red' }}>{this.state.errorUpdate}</p>
                            <MDBCol md="12" className="text-center">
                                <NewRequest class='btnCabinet'
                                    createNewLid={this.props.createNewLid} />
                                <MDBInput type="textarea" rows="5" getValue={this.handleInputChange("textRecom")} icon="pencil"
                                    name="message" valueDefault={this.state.textRecom} label="Текст приглашения" outline />

                                <MDBBtn href={this.viberText()} color="secondary">
                                    Отправить в Viber{'   '}
                                    <MDBIcon icon="paper-plane" /></MDBBtn>
                            </MDBCol>
                        </MDBCol>


                        <MDBCol md="5" className="text-center">

                            <MDBRow>
                                <MDBCol col='6' className="text-center">
                                    <h1 className="my-3">
                                        <MDBIcon icon="hands-helping" className="teal-text" /></h1>

                                    <p className="h6 m-0 p-1 rounded row tempting-azure-gradient"> Мы переведем вам по 300 гривен за каждого нового клиента TEEAL который оформит визу/безвиз по вашей рекомендации.
                                        <br />При условии, что заявка будет отправлена вами через этот кабинет</p>
                                </MDBCol>

                                <MDBCol col='6' className="text-center">
                                    <h1 className="my-3">
                                        <MDBIcon className="teal-text" far icon="thumbs-up" /></h1>

                                    <p className="h6 m-0 p-1 rgba-blue-light rounded row">А ваши знакомые получат возможность самостоятельно online изучать курс польского языка на языковой платформе <br /> 9 KROKOW - бесплатно.</p>
                                </MDBCol>
                            </MDBRow>
                            {/* <a href="https://9krokow.com/">9 KROKOW</a> */}

                            <h5 className="text-uppercase my-3 align-items-center">Ваш код рекомендации</h5>
                            <h4 className="text-uppercase my-3 align-items-center">{this.props.UserID}</h4>
                            {/*<MDBBtn color="primary">
                                 <MDBIcon icon="magic" className="mr-1" />Новая заявка</MDBBtn> */}
                            {/* <NewRequest class='btnCabinet'
                                createNewLid={this.props.createNewLid} /> */}
                        </MDBCol>
                    </MDBRow >
                </MDBContainer >
                <MDBContainer >
                    <MDBRow>
                        <MDBCol md="12" className="text-center" style={{ marginBottom: "30px", marginTop: "30px" }}>
                            <Faq />

                        </MDBCol>

                    </MDBRow>
                </MDBContainer >


            </React.Fragment >







        )
    }
}

export default Cabinet
