import React, { Component } from 'react';
import { MDBIcon, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import './NewRequest.css';






class NewRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal1: false,
            modal2: false,
            erorValidate: '',
            errorLogin: '',
            nameNewRequest: '',
            phonenewRequest: '',
            userID: localStorage.getItem('UserID'),
            newLidID: '12345'

        }


    };


    validateForm = () => {

        let name = this.state.nameNewRequest;
        let phone = this.state.phonenewRequest;

        if (name !== '' && phone !== '') {

            let decPhon = this.decorPhone(phone);
            if (decPhon) {
                console.log('validate')
                return true;
            } else {
                this.setState({ erorValidate: 'телефон должен состоять минимум из 10 цифр' })
                return false;
            }

        } else if (name === '') {
            this.setState({ erorValidate: 'Введите имя' });
            return false;

        } else if (phone === '') {
            this.setState({ erorValidate: 'Введите номер телефона' });
            return false;

        }

    }

    decorPhone = (phone) => {

        phone = phone.replace(/\D+/g, '');
        console.log(phone);
        var lenPhone = phone.length;
        var tt = phone.split('');

        if (lenPhone < 10) {
            return (false)
        } else if (lenPhone === 10) {
            tt.splice(0, 0, "+38")
        } else if (lenPhone === 11) {
            tt.splice(0, 0, "+3");
        } else if (lenPhone === 12) {
            tt.splice(0, 0, "+");
        }
        return (tt.join(''))


    }



    toggle = nr => () => {
        let modalNumber = 'modal' + nr
        this.setState({
            [modalNumber]: !this.state[modalNumber]
        });
    }

    sabmitNewRequest = (e) => {
        e.preventDefault();

        let isValidate = this.validateForm();
        if (isValidate) {
            this.setState({
                modal1: false,
                erorValidate: '',
                errorLogin: ''
            });

            //fetch('http://bonus-prod/v2/club-teeal/dat/createNewLid.php', {
            fetch(this.props.createNewLid, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: JSON.stringify({
                    'phone': this.state.phonenewRequest,
                    'name': this.state.nameNewRequest,
                    'userID': this.state.userID
                })
            })
                .then((response) => {
                    console.log(response);
                    return response.json();
                })
                .then((answText) => {
                    console.log(answText)
                    if (answText.status === 'dubl') {
                        this.setState({
                            modal1: true,
                            erorValidate: 'Клиент с этим номером уже есть в системе'

                        });
                    }
                    else if (answText.status === 'create') {
                        //status: "create"
                        //newLid: 5  благодарность за создание 
                        this.setState({
                            newLidID: answText.newLid,
                            modal1: false,
                            modal2: true
                        })
                    }

                }, (error) => {
                    this.setState({
                        modal1: true,
                        errorLogin: 'Извините. 😟 Ошибка соединеия с сервером, ' + error + ' .Проверьте, что у вас включен интернет и попробуйте чере пару минут снова'
                    })
                }

                )


        }

    };

    handleInputChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ [name]: value });
        // console.log(this.state)
    };

    reloudNewLid = () => {
        window.location.href = '/'
    }

    render() {
        return (<React.Fragment>


            <MDBBtn onClick={this.toggle(1)} className={this.props.classBtn}>
                <MDBIcon far icon="edit" />Новая заявка</MDBBtn>


            <MDBModal className="text-left" isOpen={this.state.modal1} toggle={this.toggle(1)} ullHeight position="bottom">
                <MDBModalHeader className="text-center" titleClass="w-100 font-weight-bold" toggle={this.toggle(1)}>
                    Отправьте контакт человека который интересуется работой в Польше</MDBModalHeader>
                <MDBModalBody>
                    <form onSubmit={this.sabmitNewRequest}>
                        <label htmlFor="nameNewRequest" className="grey-text"> Имя вашего знакомого/знакомой </label>

                        <input
                            required
                            type="text"
                            id="nameNewRequest"
                            name='nameNewRequest'
                            className="form-control"
                            onInput={this.handleInputChange} />
                        <br />

                        <label htmlFor="phonenewRequest" className="grey-text">Номер телефона </label>
                        <input
                            required
                            type="number"
                            name='phonenewRequest'
                            id="phonenewRequest"
                            className="form-control"
                            onInput={this.handleInputChange} />
                        <br />

                        <div className="text-center mt-4">
                            <p style={{ color: 'red' }}>{this.state.erorValidate}</p>
                            <MDBBtn outline type="submit" onClick={this.sabmitNewRequest}>
                                Отправить
                       <MDBIcon far icon="paper-plane" className="ml-2" />
                            </MDBBtn>
                        </div>
                        <p style={{ color: 'red' }}>{this.state.errorLogin}</p>
                    </form>
                </MDBModalBody>
                <MDBModalFooter className="justify-content-center">
                </MDBModalFooter>
            </MDBModal>
            {/* благодарность модал */}
            {/* <MDBBtn onClick={this.toggle}>Modal</MDBBtn> */}
            <MDBModal isOpen={this.state.modal2} toggle={this.toggle(2)}>
                <MDBModalHeader>Успех!</MDBModalHeader>
                <MDBModalBody>
                    <h3>Заявка № {this.state.newLidID} Успешно создана</h3>
                </MDBModalBody>
                <MDBModalFooter>
                    <h5>Спасибо, что вы с TEEAL</h5>
                    <MDBBtn onClick={() => {
                        this.toggle(2);
                        this.reloudNewLid()
                    }}>Закрыть</MDBBtn>

                </MDBModalFooter>
            </MDBModal>
        </React.Fragment>
        );
    }
}

export default NewRequest;