import React, { Component } from "react";
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBIcon, MDBBtn
} from "mdbreact";
import './Nav.css';
import NewRequest from './NewRequest';
import logo from './logoteeal.png'
// import { BrowserRouter as Router } from 'react-router-dom';

class NavbarPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            viberSuport: `viber://pa?chatURI=teealinfo&context=c${this.props.UserID}`,
            modalNewLid: false
        };
    };

    // componentDidMount() {
    //     console.log('nav props')
    //     console.log(this.props);
    // }



    exit = () => {
        localStorage.clear();
        window.location.href = '/'

    }


    openNewLid = () => {
        this.setState({ modalNewLid: true })
    }



    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }

    render() {
        return (
            // <Router>
            <React.Fragment>
                <MDBNavbar color="default-color" dark expand="md">
                    <MDBNavbarBrand>
                        <img className='navLogo' src={logo} height="30" alt="" />
                        <strong className='label'>Кабинет партнера</strong>
                    </MDBNavbarBrand>
                    <div className='labelCab' ></div>

                    <MDBNavbarToggler onClick={this.toggleCollapse} />
                    <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                        <MDBNavbarNav right>
                            <MDBBtn href='/' className='btnNav' ><MDBIcon icon="sync-alt" />{'  '} Обновить</MDBBtn>

                            <NewRequest classBtn='btnNav'
                                createNewLid={this.props.createNewLid} />
                            <MDBBtn className='btnNav' href={this.state.viberSuport} >
                                <MDBIcon fab icon="viber" /> Помощь</MDBBtn>
                            <MDBBtn className='btnNav' onClick={this.exit} ><MDBIcon icon="sign-out-alt" /> Выход</MDBBtn>
                        </MDBNavbarNav>
                    </MDBCollapse>

                </MDBNavbar>
            </React.Fragment>
            // </Router>
        );
    }
}

export default NavbarPage;