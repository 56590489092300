import React, { Component } from "react";
import { MDBCollapse, MDBContainer, MDBListGroupItem, MDBDataTable, MDBIcon, } from "mdbreact";


class GetAllrefLid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openStat: false,
            errorUpdate: '',
            rows: '',
            loudRez: ''
        }
    }

    toggleCollapse = () => {

        this.setState({ openStat: !this.state.openStat })

        if (!this.state.openStat) {
            this.getAllStat()

        }
        // this.setState(prevState => ({
        //     collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        // }));
    }

    getAllStat = () => {

        this.setState({
            errorUpdate: '',
            loudRez: <MDBIcon icon="spinner" pulse size="1x" fixed />
        })


        if (this.props.UserID) {
            //console.log(this.state)
            let UserID = this.props.UserID
            //fetch(`http://bonus-prod/v2/club-teeal/dat/getAllrefLid.php?UserID=${UserID}`
            fetch(`${this.props.getAllrefLid}?UserID=${UserID}`
                // , {
                //     //fetch('./dat/getAllrefLid.php', {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/x-www-form-urlencoded'
                //     },
                //     body: JSON.stringify({ 'UserID': this.props.UserID })
                // }
            )
                .then((response) => {

                    return response.json();

                })
                .then((answ) => {
                    //  console.log(answ)
                    let r = this.DatatablePage(answ);

                    this.setState({
                        rows: r,
                    })
                    setTimeout(() => {
                        this.setState({ loudRez: '' })
                    }, 800)

                }
                    , (error) => { this.errorFetch(error); });
        };

    }




    errorFetch = (error) => {
        console.log(error)
        this.setState({
            errorUpdate: 'Извините. 😟 Ошибка соединеия с сервером, ' + error + ' .Проверьте, что у вас включен интернет и перезагрузите страницу'
        })
    }




    DatatablePage = (answ) => {



        const data = {
            columns: [
                {
                    label: '№',
                    field: 'lid',
                    sort: 'disabled',
                    width: 50
                },
                {
                    label: 'Создана',
                    field: "DATE_FORMAT(`dateCreate`, \"%e.%m.%y %H:%i\")",
                    sort: 'asc',
                    width: 300
                },
                {
                    label: 'Статус заявки',
                    field: 'status',
                    sort: 'asc',
                    width: 800
                }
            ],
            rows: answ
            //this.createRows()
        };

        return (
            // <MDBTable responsiveXl>
            //     <MDBTableHead columns={data.columns} />
            //     <MDBTableBody rows={data.rows} />
            // </MDBTable>
            <MDBDataTable responsiveXl
                // paginationLabel={["Предыдущая", "Следующая"]}
                // infoLabel={["Показны", "-", "из", "записей"]}
                searching={false}
                autoWidth
                // fixed
                striped
                bordered
                noBottomColumns
                hover
                displayEntries={false}
                // theadColor="default-collor"
                // color='default-color'
                theadColor="teal"
                theadTextWhite
                // tbodyColor="indigo"
                noRecordsFoundLabel="Нет данных для отображения"
                paging={false}
                order={["DATE_FORMAT(`dateCreate`, \"%e.%m.%y %H:%i\")", 'asc']}
                data={data} />
        );

    }







    render() {
        return (
            <React.Fragment >
                <MDBListGroupItem onClick={this.toggleCollapse} className="d-flex justify-content-center align-items-center">
                    <h5> <MDBIcon className="teal-text" far icon="list-alt" /> Подрбнее  {this.state.loudRez}</h5>

                </MDBListGroupItem>

                <MDBContainer className='d-flex justify-content-center cabinetAll' style={{ padding: '0px' }} >
                    <MDBCollapse isOpen={this.state.openStat} >
                        <p>{this.state.errorUpdate}</p>
                        <div >
                            {this.state.rows}</div>
                        {/* <MDBListGroupItem color="dark" onClick={this.toggleCollapse} className="d-flex justify-content-center align-items-center" > */}
                        <h5 className='d-flex justify-content-center ' onClick={this.toggleCollapse}>Свернуть <MDBIcon style={{ paddingLeft: '10px' }} icon="caret-up" /> </h5>
                        {/* </MDBListGroupItem> */}
                    </MDBCollapse>
                </MDBContainer>

            </React.Fragment >
        )
    }
}

export default GetAllrefLid;