import React, { Component } from "react";
import { MDBCollapse, MDBCard, MDBCardBody } from "mdbreact";

class Faq extends Component {
    state = {
        collapseID: ""
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }

    render () {
        return (
            <React.Fragment >
                <MDBCard>
                    <MDBCardBody onClick={this.toggleCollapse("1")}>Сколько я могу заработать ?</MDBCardBody>
                </MDBCard>
                <MDBCollapse id="1" isOpen={this.state.collapseID}>
                    <h5 style={{ margin: "10px" }}>
                        К примеру- «Вы отправили 10 знакомым которые возможно поедут,
                        по факту решили сделать визу 3 человека, вы получаете за 2 минуты своего времени 900 грн.»
                    </h5>
                </MDBCollapse>
                <MDBCard>
                    <MDBCardBody onClick={this.toggleCollapse("0")}>Что означают стаусы заявок ?</MDBCardBody>
                </MDBCard>
                <MDBCollapse id="0" isOpen={this.state.collapseID}>
                    < div style={{ margin: "10px" }}>
                        <b> "Ноавя заявка"</b>- Мы получили заявку по вашей рекомендации, но еще не успели свзятся с клиентом, либо он еще приниает решение об оформлении визы</div>
                    < div style={{ margin: "10px" }}>
                        <b> "Предварительно подтверждена"</b>- Клиент согласен оформить визу, но еще не внес аванс</div>
                    < div style={{ margin: "10px" }}>
                        <b> "Подтверждена"</b>- Клиент внес аванс, в ближайшее время мы сделаем вам перевод выплаты</div>
                    < div style={{ margin: "10px" }}>
                        <b> "Выполнена"</b>- Мы произвели расчет по заявке</div>
                    < div style={{ margin: "10px" }}>
                        <b> "Отклонена"</b>- Мы в течении долгого времени не смогли связатся по заявке, либо клиент не желает оформлять визу. Обратитесь к вашему специалисту, за более детальной информацией</div>


                </MDBCollapse>
                <MDBCard>
                    <MDBCardBody onClick={this.toggleCollapse("2")}>Когда я получаю свой бонус?</MDBCardBody>
                </MDBCard>
                <MDBCollapse id="2" isOpen={this.state.collapseID} >
                    <h5 style={{ margin: "10px" }}>
                        После того как Ваш знакомый подтвердил готовность в открытию визы оплатив аванс,
                        в течение 5 дней наша бухгалтерия уточнит у Вас номер карты для перевода оплаты
                    </h5>
                </MDBCollapse>
                <MDBCard>
                    <MDBCardBody onClick={this.toggleCollapse("3")}>Если у меня есть знакомый который хочет поехать но у него нету вайбера?</MDBCardBody>
                </MDBCard>
                <MDBCollapse id="3" isOpen={this.state.collapseID} >
                    <h5 style={{ margin: "10px" }}>
                        Вы можете сами отправить его имя и номер телефона, нажав кнопку "Новая заявка" на этой странице.
                    </h5>
                </MDBCollapse>
                <MDBCard>
                    <MDBCardBody onClick={this.toggleCollapse("4")}>Откуда вы узнаете что этот человек по моей рекомендации?</MDBCardBody>
                </MDBCard>
                <MDBCollapse id="4" isOpen={this.state.collapseID} >
                    <h5 style={{ margin: "10px" }}>
                        Если ваш знакомый перешел по ссылке и указал свой номер эта информация будет зафиксирована в его карточке, если вы сами отправили заявку на этой странице  она так-же будет зафиксирована и бухгалтерия сделает выплату.
                    </h5>
                </MDBCollapse>
                <MDBCard>
                    <MDBCardBody onClick={this.toggleCollapse("5")}>Данная программа лояльности распространяется на трудоустройство по Без визу ?</MDBCardBody>
                </MDBCard>
                <MDBCollapse id="5" isOpen={this.state.collapseID} >
                    <h5 style={{ margin: "10px" }}>
                        Да! вы получите перевод в размере 300 грн и в том случае если клиент по вашей рекомендации получит у нас пакет документов для безвиза
                    </h5>
                </MDBCollapse>
                <MDBCard>
                    <MDBCardBody onClick={this.toggleCollapse("6")}>Скольким людям я могу отправить такое сообщение?</MDBCardBody>
                </MDBCard>
                <MDBCollapse id="6" isOpen={this.state.collapseID} >
                    <h5 style={{ margin: "10px" }}>
                        Количество людей с нашей стороны не ограничено
                    </h5>
                </MDBCollapse>
            </React.Fragment >
        );
    }
}

export default Faq;